import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from "react-i18next";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "./myswiper.css";
import { FreeMode, Navigation, Autoplay } from "swiper";

export default (props) => {
	const [activeIndex, setActiveIndex] = useState();
	const { t } = useTranslation();

	const { activtIndexfn } = props;

	return (
		<>
			<Swiper
				slidesPerView={3.2}
				spaceBetween={40}
				freeMode={true}
				loop={true}
				navigation={true}
				modules={[FreeMode, Navigation, Autoplay]}
				className="mySwiper swiper-no-swiping"
				onSlideChange={(e) => {
					setActiveIndex(e.realIndex);
					activtIndexfn(e.realIndex);
				}}
				autoplay={{ delay: 4000 }}
			>
				{t("roadMap", { returnObjects: true }).map((li, index) => {
					return (
						<SwiperSlide key={index}>
							<div
								key={index}
								className={
									activeIndex == index ? "roadmap2 active" : "roadmap2"
								}
							>
								<p className="roadmaph1">{li.year} </p>
								<p className="roadmaph2">{li.mouth}</p>
								{li.text.map((u, x) => {
									return (
										<p className="roadmaph3 small12" key={x}>
											{u}
										</p>
									);
								})}
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</>
	);
};
