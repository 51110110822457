import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Data from "./home/Data";
import SwiperInner from "./home/SwiperInner";
import "swiper/css/bundle";
import "./i18n";
import "./App.scss";

import logo from "./assets/Logo.png";
import but from "./assets/polygon.png";
import icon1 from "./assets/icon1.png";
import icon2 from "./assets/icon2.png";
import icon3 from "./assets/icon3.png";
import icon4 from "./assets/icon4.png";
import logo_join from "./assets/logo_join.png";
import "./components/tips";

export default () => {
	const [selectactive, setSelectactive] = useState(0);
	const [time, setTime] = useState(0);
	const [topLinght, setTopLinght] = useState(false);
	const { t } = useTranslation();

	const slidePrev = (index) => {
		if (index === 0) {
			document.getElementsByClassName("swiper-button-prev")[0].click();
		} else {
			document.getElementsByClassName("swiper-button-next")[0].click();
		}
	};

	const activtIndexfn = (index) => {
		setTime(index + 1);
	};

	useEffect(() => {
		window.addEventListener("scroll", (e) => {
			let scrollTop = document.documentElement.scrollTop;

			if (scrollTop > 0) {
				setTopLinght(true);
			} else {
				setTopLinght(false);
			}

			// if (scrollTop <= 980) {
			// 	setSelectactive(0); // home
			// }
			// if (scrollTop <= 2000 && scrollTop > 980) {
			// 	// NFTs
			// 	setSelectactive(1);
			// }
			// if (scrollTop <= 3000 && scrollTop > 2000) {
			// 	// rm
			// 	setSelectactive(2);
			// }
			// if (scrollTop <= 4150 && scrollTop > 3000) {
			// 	// tm
			// 	setSelectactive(3);
			// }
			// if (scrollTop > 4150) {
			// 	setSelectactive(4);
			// }

			if (scrollTop <= 880) {
				setSelectactive(0);
			}
			if (scrollTop <= 2300 && scrollTop > 880) {
				setSelectactive(1);
			}
			if (scrollTop <= 2700 && scrollTop > 2300) {
				setSelectactive(2);
			}
			if (scrollTop <= 3572 && scrollTop > 2700) {
				setSelectactive(3);
			}
			// if (scrollTop > 3572) {
			// 	setSelectactive(4);
			// }
		});
	}, []);

	return (
		<div className="App">
			<div className={topLinght ? "header active" : "header"}>
				<div className="centerHeader">
					<img src={logo} alt="logo" className="logo" />
					<div className="cenerNav">
						{t("header", { returnObjects: true }).map((item, index) => {
							return (
								<a
									className={
										item.id === selectactive
											? "textNav selectactive"
											: "textNav"
									}
									key={index}
									onClick={(e) => {
										if (item.url) {
											window.open(item.url);
										} else {
											setSelectactive(index);
											// console.log(
											// 	document.getElementById(item.id).offsetTop,
											// 	index,
											// 	111
											// );
											window.scrollTo({
												top: document.getElementById(item.id).offsetTop,
												behavior: "smooth",
											});
										}
									}}
								>
									{item.text}
								</a>
							);
						})}
					</div>
					<div className="launch">
						{/* <img src={but} alt="" className="but" /> */}
						<div
							onClick={() => {
								window.open("https://app.apollofi.xyz/");
							}}
							className="text color"
						>
							{t("launchBtn")}
						</div>
					</div>
				</div>
			</div>

			<div className="homeTop" id="0">
				<div className="banner">
					<div className="bannertext">
						<p className="hd">{t("banner", { returnObjects: true }).welcome}</p>
						<p className="bd1">
							{t("banner", { returnObjects: true }).t1}{" "}
							<a href="#" className="blue">
								{t("banner", { returnObjects: true }).web}
							</a>{" "}
							{t("banner", { returnObjects: true }).t2}
						</p>
						<p className="bd2">{t("banner", { returnObjects: true }).t3}</p>
					</div>
				</div>
				<Data></Data>
			</div>

			<div className="cardinfo cardinfo-bg" id="1">
				<div className="cardinfobox">
					<p className="text1">{t("cardinfo", { returnObjects: true }).t1}</p>
					<p className="text2">{t("cardinfo", { returnObjects: true }).t2}</p>

					<a-tips tips="COMING SOON">
						<p className="text3">
							{t("cardinfo", { returnObjects: true }).btn}
						</p>
					</a-tips>
				</div>
				<div className="cardinfobox centerbox small14">
					<div className="icon">
						<img src={icon1} alt="" />
						<p>{t("cardinfo", { returnObjects: true }).card1}</p>
					</div>
					<div className="icon">
						<img src={icon2} alt="" />
						<p>{t("cardinfo", { returnObjects: true }).card2}</p>
					</div>
					<div className="icon">
						<img src={icon3} alt="" />
						<p>{t("cardinfo", { returnObjects: true }).card3}</p>
					</div>
					<div className="icon">
						<img src={icon4} alt="" />
						<p>{t("cardinfo", { returnObjects: true }).card4}</p>
					</div>
				</div>

				<div className="cardinfobox centerbox mt80">
					{t("cardinfo2", { returnObjects: true }).map((item, index) => {
						return (
							<div className="cardinfotext" key={index}>
								<p className="h1">{item.title}</p>
								<p className="h3 small14">
									{item.t1}
									<br />
									{item.t2}
									<br />
									{item.t3}
									<br />
									{item.t4}
									<br />
									{item.t5}
									<br />
									{item.t6}
									<br />
									{item.t7}
									<br />
									{item.t8}
									
								</p>
							</div>
						);
					})}
				</div>
			</div>

			<div className="roadmap" id="2">
				<div className="centerMap">
					<div className="roadmap2">
						<p className="mapbig">
							{t("roadMapDesc", { returnObjects: true }).title}
						</p>
						<p className="maptext small14">
							{t("roadMapDesc", { returnObjects: true }).text}
						</p>
						<div className="iconimg swiper-pagination-wraper">
							<div
								className="slide_btn_prev"
								onClick={() => {
									slidePrev(0);
								}}
							></div>
							<div
								className="slide_btn_next"
								onClick={() => {
									slidePrev(1);
								}}
							></div>
						</div>
						<div>
							<span className="red">{time}</span>
							<span className="opacity5">
								/{t("roadMap", { returnObjects: true }).length}
							</span>
						</div>
					</div>
					<div className="rightBox">
						<div className="rightBoxSwiper">
							<SwiperInner activtIndexfn={activtIndexfn}></SwiperInner>
						</div>
					</div>
				</div>
			</div>

			<div className="team" id="3">
				<div className="container">
					<header>
						<h4>Team</h4>
						<p>
							ApolloFi is a collective of strong crypto believers whose vocation
							is Web3 and NFT. We are a team of 16 coming from North
							<br /> America, Europe, Southeast Asia, and other regions.
						</p>
					</header>
					<section>
						{t("team", { returnObjects: true }).cards.map((item, index) => (
							<div className={`card card-${index + 1}`} key={index}>
								<div className="top-bg"></div>
								<div className="avatar-img">
									<img src={require(`./assets/boy-${index + 1}.png`)} alt="" />
								</div>
								<div className="info">
									<p className="user-name">{item.name}</p>
									<p className="title">{item.title}</p>
									<p className="desc">{item.desc}</p>
								</div>
							</div>
						))}
					</section>
				</div>
			</div>

			<div className="join" id="">
				<div className="joinleft">
					<p className="jointext1">{t("join", { returnObjects: true }).t1}</p>
					<p className="jointext2">{t("join", { returnObjects: true }).t2}</p>
					<p
						className="joinbut text3"
						onClick={() => {
							window.open(
								"https://docs.google.com/forms/d/e/1FAIpQLSelQA8WT0BxGw2z83lsTaaaFSSRp6-C3QTRdTjnUD0J4UutMg/viewform"
							);
						}}
					>
						{t("join", { returnObjects: true }).btn}
					</p>
				</div>
				<div className="joinright">
					<img src={logo_join} alt="" className="logo_join" />
				</div>
			</div>

			<div className="bottombox" id="">
				<div className="org">
					<p className="org_text">{t("bottomBoxTitle")}</p>
					<div className="org_img">
						{t("bottomBox", { returnObjects: true }).map((item, index) => (
							<div key={index}>
								<img src={require(`./assets/${item.img}.png`)} alt="" />
								{item.name}
							</div>
						))}
					</div>
				</div>

				<div className="bottom-icon">
					{t("bottomIcon", { returnObjects: true }).map((item, index) => (
						<>
							{item.name === "email" ? (
								<a-tips tips={item.url} key={index}>
									<a
										title={item.url}
										href={`mailto:${item.url}`}
										key={index}
										className={`icon-wrap ${item.name}-logo`}
									></a>
								</a-tips>
							) : (
								<a
									onClick={() => {
										if (item.url) window.open(item.url);
									}}
									key={index}
									className={`icon-wrap ${item.name}-logo`}
								></a>
							)}
						</>
					))}
				</div>
				<p className="end small14">{t("copyright")}</p>
			</div>
		</div>
	);
};
