import React from "react"
import CountUp from 'react-countup';
import { useMemo } from "react"

export default props => {
  const { num, decimals } = props
  return useMemo(() => {
    return  <CountUp separator="," end={Number(num)} duration={3} decimals={decimals} />
  }, [num])
}
