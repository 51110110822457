import React, { useState, useEffect } from "react";
// Import Swiper React components
import { useTranslation } from "react-i18next";
import "./data.css";
import axios from "axios";
import Counter from "./counter";

function isInClient() {
  const el = document.querySelectorAll(".numBox")[0];
  let { top, right, bottom, left } = el.getBoundingClientRect();
  let clientHeight =
    window.innerHeight || document.documentElement.clientHeight;
  let clientWidth = window.innerWidth || document.documentElement.clientWidth;

  return !(top > clientHeight || bottom < 0 || left > clientWidth || right < 0);
}

export default (props) => {
  const [post_address, setPost_address] = useState(0);
  const [total_address, setTotal_address] = useState(0);
  const [result, setResult] = useState({});

  const checkAll = () => {
    if (isInClient()) {
      if (result.post_address) {
        setPost_address(result.post_address);
        setTotal_address(result.total_address);
      }
    }
  };

  useEffect(() => {
    checkAll();
  }, [result]);

  useEffect(() => {
    window.addEventListener("scroll", checkAll);
    return () => {
      window.removeEventListener("scroll", checkAll);
    };
  }, [result]);

  useEffect(() => {
    axios.get("https://api.apollofi.xyz/api/v1/public/info").then((res) => {
      setResult(res.data?.data || {});
      if (isInClient()) {
        setPost_address(res.data?.data?.post_address || 0);
        setTotal_address(res.data?.data?.total_address || 0);
      }
    });
  }, []);

  const { t, i18n } = useTranslation();

  return (
    <div className="Databg" styles="">
      <div className="inner">
        <div className="numBox">
          <div className="numText">
            $--
            {/* <CountUp start={0} end={0} duration={1}></CountUp> */}
          </div>
          <div className="nutText">{t("data", { returnObjects: true }).t1}</div>
        </div>
        <div className="numBox">
          <div className="numText">
            <Counter num={post_address} decimals={0} />
          </div>
          <div className="nutText">{t("data", { returnObjects: true }).t2}</div>
        </div>
        <div className="numBox">
          <div className="numText">
            <Counter num={total_address} decimals={0} />
          </div>
          <div className="nutText">{t("data", { returnObjects: true }).t3}</div>
        </div>
      </div>
    </div>
  );
};
